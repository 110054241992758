import { getRequest, postRequest, downloadRequest, patchRequest, deleteRequest } from "@/libs/axios";

// ------------------------文件管理-----------------------
// 文件列表
export function fileList(params) {
    return getRequest("/yethan/web/file/listPage", params);
}
// 文件修改状态
export function changeTop(params) {
    return patchRequest("/yethan/web/file/changeTop", params);
}
// 删除文件
export function deleteFile(sid) {
    return deleteRequest("/yethan/web/file/"+sid);
}
// 文件分类列表
export function typeList(params) {
    return getRequest("/yethan/web/file/typeList", params);
}
// 保存文件
export function saveFile(params) {
    return postRequest("/yethan/web/file", params);
}
